import RulesOperations from './rulesOperations';

const defFilters = {
	src: '',
	trg: '',
};

const dialogs = {
	addNewRule: {
		show: false,
	},
};

const initialState = {
	info: {},
	rules: [],
	displayRules: [],

	// UI
	loading: false,
	activeItem: null,
	forceRender: 0,

	dialogs,

	// Filters
	filters: { ...defFilters },
};

const RSReducer = (state = initialState, action) => {
	const { payload, type } = action;

	let tmpState;

	switch (type) {
		case 'rsEditor/setFilters':
			tmpState = {
				...state,
				filters: { ...state.filters },
			};

			tmpState.filters = {
				...payload,
			};

			return tmpState;

		case 'rsEditor/filterAllRules':
			return RulesOperations.filterAllRules(state);

		case 'rsEditor/setInfo':
			return {
				...state,
				...initialState,
				info: { ...payload },
			};

		case 'rsEditor/setProperty':
			return {
				...state,
				...payload,
			};

		case 'rsEditor/updateRule':
			return RulesOperations.updateRule(state, payload);

		case 'rsEditor/setActiveItem':
			return RulesOperations.setActiveItem(state, payload);

		case 'rsEditor/setItemToChecked':
			return RulesOperations.setItemToChecked(state, payload);
		case 'rsEditor/setItemToCheckedRange':
			return RulesOperations.setItemToCheckedRange(state, payload);

		case 'rsEditor/removeRules':
			return RulesOperations.removeRules(state, payload);

		case 'rsEditor/updateRules':
			return RulesOperations.updateRules(state, payload);

		case 'rsEditor/showDialog':
			tmpState = {
				...state,
				dialogs: { ...state.dialogs },
			};

			tmpState.dialogs[payload.key] = {
				show: true,
				data: payload?.data
					? payload.data
					: tmpState.dialogs[payload.key]?.data,
			};

			return tmpState;

		case 'rsEditor/hideDialog':
			tmpState = {
				...state,
				dialogs: { ...state.dialogs },
			};

			tmpState.dialogs[payload.key] = {
				show: false,
				data: tmpState.dialogs[payload.key]?.data,
			};

			return tmpState;

		default:
			return state;
	}
};

export default RSReducer;
