import produce from 'immer';
import { escapeRegExp } from '../../translationEditor/commonFuncs';

class RulesOperations {
	static setActiveItem(state, payload) {
		//get active item from stack in order to reflect any changes not saved yet
		return produce(state, (newState) => {
			newState.activeItem =
				newState.rules.find((T) => T?.id == payload?.id) ?? null;
			newState.rules = newState.rules.map((T) => {
				if (!T) return null;

				T.checked = T.id == payload?.id;
				return T;
			});
		});
	}

	static setItemToChecked(state, payload) {
		const { id, checked } = payload;
		const newState = {
			...state,
			activeItem: null,
			rules: state.rules.map((T) => {
				if (!T) return null;

				if (id == T.id) {
					T.checked = checked;
				}

				return T;
			}),
		};

		return newState;
	}

	static setItemToCheckedRange(state, payload) {
		const { index } = payload;

		const newState = {
			...state,
			rules: [...state.rules],
			forceRender: new Date(),
		};

		//find 1st segment checked
		const firstCheckedSegment = newState.rules.find((s) => s.checked);
		if (!firstCheckedSegment)
			//not found!
			return newState;

		let i;

		if (firstCheckedSegment.idx < index) {
			for (i = firstCheckedSegment.idx; i <= index; i++) {
				newState.rules[i].checked = true;
			}
			newState.activeSegment = null;
		}

		if (firstCheckedSegment.idx > index) {
			for (i = firstCheckedSegment.idx; i >= index; i--) {
				newState.rules[i].checked = true;
			}
			newState.activeSegment = null;
		}

		return newState;
	}

	static updateRule(state, payload) {
		return produce(state, (newState) => {
			newState.forceRender = new Date();
			newState.rules = newState.rules.map((T) => {
				if (!T) return null;

				if (T.id == payload.id) {
					T = produce(T, (newT) => {
						//update any given pair
						Object.keys(payload).forEach((k) => {
							newT[k] = payload[k];
						});

						newT.hasChanges = true;
					});
				}

				return T;
			});

			// Also update displayRules obj
			newState.displayRules[payload?.id].hasChanges = true;
			Object.keys(payload).forEach((k) => {
				newState.displayRules[payload?.id][k] = payload[k];
			});

			if (payload.id == newState?.activeItem?.id) {
				newState.activeItem = {
					...newState.activeItem,
					...payload,
				};
			}
		});
	}

	static removeRules(state, payload) {
		const { rules } = payload;

		if (!rules || rules.length == 0) return state;

		return produce(state, (newState) => {
			newState.rules = state.rules.filter((T) => {
				if (T && rules.includes(T.id)) return false;

				return true;
			});

			rules.forEach((rule) => delete newState.displayRules[rule]);

			newState.forceRender = new Date();

			if (newState.activeSegment && rules.includes(newState.activeSegment.id))
				newState.activeSegment = null;
		});
	}

	static updateRules(state, payload) {
		const { rules } = payload;

		if (!rules || rules.length == 0) return state;

		const ids = rules.map((I) => I.id);

		return produce(state, (newState) => {
			newState.forceRender = new Date();
			newState.rules = newState.rules.map((T) => {
				if (!T) return null;

				if (ids.includes(T.id)) {
					//update this
					//const updatedItem = rules.find( I => I.id == T.id);

					T.hasChanges = false;
				}
				return T;
			});

			rules.forEach(
				(
					rule // Also update displayRules obj
				) => (newState.displayRules[rule?.id].hasChanges = false)
			);
		});
	}

	static filterAllRules(state) {
		const newState = {
			...state,
			forceRender: new Date(),
		};

		const filteredRules = {};

		const activeFilters = newState.filters;

		newState.rules.forEach((rule) => {
			let thisCriteria = true;
			let ruleMatchesCriteria = true;

			// searchFor text filter
			const regexSearchFor = new RegExp(escapeRegExp(activeFilters?.src), 'gi');

			thisCriteria = regexSearchFor.test(rule?.searchFor);
			ruleMatchesCriteria = ruleMatchesCriteria && thisCriteria;

			// replaceWith text filter
			const regexReplaceWith = new RegExp(
				escapeRegExp(activeFilters?.trg),
				'gi'
			);

			thisCriteria = regexReplaceWith.test(rule?.replaceWith);

			ruleMatchesCriteria = ruleMatchesCriteria && thisCriteria;

			if (ruleMatchesCriteria) {
				filteredRules[rule.id] = rule;
			}
		});

		newState.displayRules = filteredRules;

		return newState;
	}
}

export default RulesOperations;
