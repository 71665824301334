export const GA_CODE = process?.env?.NEXT_PUBLIC_GA_CODE ?? null;

export const pageview = (url) => {
	if (!GA_CODE) return;
	window.gtag('config', GA_CODE, {
		page_path: url,
	})
}

export const event = ({ action, category, label, value }) => {
	if (!GA_CODE) return;
	window.gtag('event', action, {
		event_category: category,
		event_label: label,
		value: value,
	})
}