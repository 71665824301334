async function fetchWithAuth(url, params = {}, req) {
	const headers = req?.headers ?? {};

	const { throwOnHttpErrorStatus, ...restParams } = params;

	const _params = {
		...restParams,
		headers: {
			'Content-Type': 'application/json',
			...headers,
			host: `${process?.env?.FRONTEND_URL?.replace('https://', '')?.replace('http://', '')}`,
		},
	};

	return fetch(url, _params).then((r) => {
		if (throwOnHttpErrorStatus && !r.ok) {
			return r.json().then((err) => Promise.reject(err));
		}

		return r.json();
	});
}

export default fetchWithAuth;
