import { DefaultSeo, NextSeo } from "next-seo";
import getConfig from "next/config";
import { useRouter } from "next/router";

const { publicRuntimeConfig: { FRONTEND_URL } } = getConfig()

const defaults = {
    title: "Robert",
    titleTemplate: "%s",
    description: "All-in-one localization platform",
    canonical: FRONTEND_URL,
    openGraph: {
        type: "website",
        locale: "en",
        url: FRONTEND_URL,
        site_name: "Robert",
        images: [{
            url: `${FRONTEND_URL}/img/fbShare_1.jpg`,
            width: 1200,
            height: 628,
            alt: "Robert",
        }],
    },
    dangerouslySetAllPagesToNoIndex: true,
    dangerouslySetAllPagesToNoFollow: true,
}

export default function Component() {
    return <DefaultSeo {...defaults} />
}

type Props = {
    title: string
}

export function WebpageSEO({ title }: Props) {
    const { asPath } = useRouter()
    const asPathWithoutQuery = asPath.split("?")[0]
    const absoluteUrl = `${FRONTEND_URL}${asPathWithoutQuery}`

    return (
        <NextSeo
            title={title}
            canonical={absoluteUrl}
            openGraph={{
                url: absoluteUrl,
            }}
        />
    )
}
