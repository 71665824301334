import { escapeRegExp } from '../../translationEditor/commonFuncs';

class ItemsOperation {
	static setActiveItem(state, activeItem) {
		const newState = {
			...state,
			activeItem,
			terms: state.terms.map((T) => {
				if (!T) return null;

				T.checked = T.id == activeItem.id;
				return T;
			}),
		};

		return newState;
	}

	static setItemToChecked(state, payload) {
		const { id, checked } = payload;
		const newState = {
			...state,
			activeItem: null,
			terms: state.terms.map((T) => {
				if (!T) return null;

				if (id == T.id) {
					T.checked = checked;
				}

				return T;
			}),
		};

		return newState;
	}

	static setItemToCheckedRange(state, payload) {
		const { index } = payload;

		const newState = {
			...state,
			terms: [...state.terms],
			forceRender: new Date(),
		};

		//find 1st segment checked
		const firstCheckedSegment = newState.terms.find((s) => s.checked);
		if (!firstCheckedSegment)
			//not found!
			return newState;

		let i;

		if (firstCheckedSegment.idx < index) {
			for (i = firstCheckedSegment.idx; i <= index; i++) {
				newState.terms[i].checked = true;
			}
			newState.activeSegment = null;
		}

		if (firstCheckedSegment.idx > index) {
			for (i = firstCheckedSegment.idx; i >= index; i--) {
				newState.terms[i].checked = true;
			}
			newState.activeSegment = null;
		}

		return newState;
	}

	static updateTerm(state, payload) {
		console.log('payload', payload);
		const newState = {
			...state,
			terms: state.terms.map((T) => {
				if (!T) return null;

				if (T.id == payload.id) {
					T = {
						...T,
						...payload,
						hasChanges: true,
					};
				}

				return T;
			}),
			forceRender: new Date(),
		};

		return newState;
	}

	static removeTerms(state, payload) {
		const { ids, targetLangsCount } = payload;

		if (!ids || ids.length == 0) return state;

		const newState = {
			...state,
			terms: state.terms.filter((T) => {
				if (T && ids.includes(T.id)) return false;

				return true;
			}),
			forceRender: new Date(),
		};

		//chk active segmnet
		if (newState.activeSegment && ids.includes(newState.activeSegment.id))
			newState.activeSegment = null;

		newState.info.targetLangsStats = {
			...newState.info.targetLangsStats,
			...targetLangsCount,
		};

		//stats
		newState.info.termsCount -= ids.length;
		newState.termsCount -= ids.length;

		if (newState.info.termsCount < 0) newState.info.termsCount = 0;

		if (newState.termsCount < 0) newState.termsCount = 0;

		return newState;
	}

	static updateTerms(state, payload) {
		const { items } = payload;

		if (!items || items.length == 0) return state;

		const ids = items.map((I) => I.id);


		const newState = {
			...state,
			terms: state.terms.map((T) => {
				if (!T) return T;

				const updatedItem = items.find(({ id }) => id == T.id);
				let item = updatedItem ?? T;

				if (ids.includes(item.id)) {
					item.hasChanges = false;
				}

				return updatedItem ?? T;
			}),
			forceRender: new Date(),
		};

		return newState;
	}
}

export default ItemsOperation;
