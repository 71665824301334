export const getLangCodeForChrome = (targetLang) => {
	if (!targetLang) {
		return '';
	}

	if (targetLang === 'en-gb') {
		return 'en-GB';
	}

	return targetLang.split('-')[0];
};

export const nextTick = (fn) => {
	Promise.resolve().then(fn);
};

export function getMultipleIndexes(
	sentence,
	_word,
	_case_sensitive,
	_whole_words,
	_regex_search,
	_multiline
) {
	/*besides '_word' param, others are flags (0|1)*/
	const flags = ['g', _case_sensitive ? '' : 'i', _multiline ? 'm' : ''];

	let regex = _regex_search ? String.raw`${_word}` : escapeRegExp(_word);
	if (_whole_words && _word) regex = String.raw`\b${regex}\b`;

	var _pos = [],
		_chunk;

	try {
		var _re = new RegExp(regex, flags.join(''));

		while (true) {
			_chunk = _re.exec(sentence);
			if (_chunk == null) break;
			_pos.push(_chunk['index']);
			_re.lastIndex = _chunk['index'] + 1;
		}
	} catch (e) {}

	return _pos;
}

//#region Undo Redo
export function pushToUndo(state, arSegments) {
	const segmentsToChange = arSegments.map((S) => {
		return { ...S };
	});

	if (segmentsToChange.length > 0) {
		state = {
			...state,
			undoStack: [[...segmentsToChange], ...state.undoStack],
		};
	}

	return state;
}

//#endregion

export const forceRender = (state) => {
	state.lastUpdate = +new Date();
	return state;
};

export const placeCaretAtEnd = (el) => {
	if (!el) return;

	if (
		typeof window.getSelection != 'undefined' &&
		typeof document.createRange != 'undefined'
	) {
		var range = document.createRange();
		range.selectNodeContents(el);
		range.collapse(false);
		var sel = window.getSelection();
		sel.removeAllRanges();
		sel.addRange(range);
	} else if (typeof document.body.createTextRange != 'undefined') {
		var textRange = document.body.createTextRange();
		textRange.moveToElementText(el);
		textRange.collapse(false);
		textRange.select();
	}
};

export const insertTextAtCursor = (
	text,
	cursorOffset = 1,
	selectionExternal = null
) => {
	if (window?.activeEditor) {
		const selection = selectionExternal ?? window.activeEditor.getSelection();

		//if no focus, return
		if (selection) {
			window.activeEditor.insertText(selection?.index, text, 'user');

			// Check if there's selected text, instead of just cursor at position
			// and delete selection to create a replacing effect
			if (selection?.length > 0) {
				window.activeEditor.deleteText(
					selection?.index + text.length,
					selection?.length,
					'user'
				);
			}
			//move cursor
			nextTick(() => {
				window.activeEditor.setSelection(selection?.index + cursorOffset, 0);
			});

			return;
		}
	}

	const activeElement = document.activeElement;
	let valueSet;

	if (activeElement instanceof HTMLInputElement) {
		valueSet = Object.getOwnPropertyDescriptor(
			window.HTMLInputElement.prototype,
			'value'
		).set;
	}
	if (activeElement instanceof HTMLTextAreaElement) {
		valueSet = Object.getOwnPropertyDescriptor(
			window.HTMLTextAreaElement.prototype,
			'value'
		).set;
	}

	if (
		!valueSet ||
		activeElement.selectionStart == null ||
		activeElement.selectionEnd == null
	)
		return;

	const selectionStart = activeElement.selectionStart;
	const selectionEnd = activeElement.selectionEnd;

	valueSet.call(
		activeElement,
		activeElement.value.slice(0, selectionStart) +
			text +
			activeElement.value.slice(selectionEnd)
	);

	activeElement.dispatchEvent(new Event('input', { bubbles: true }));

	activeElement.selectionStart = activeElement.selectionEnd =
		selectionStart + text.length;
};

export const removeTextAtCursor = (cursorOffset = 1) => {
	if (window?.activeEditor) {
		const selection = window.activeEditor.getSelection();

		if (selection?.index - cursorOffset >= 0) {
			window.activeEditor.deleteText(
				selection?.index - cursorOffset,
				cursorOffset,
				'user'
			);
		}
	}
};

// export const insertTextAtCursor = (text) => {
// 	var sel, range, html;
// 	sel = window.getSelection();
// 	range = sel.getRangeAt(0);
// 	range.deleteContents();
// 	var textNode = document.createTextNode(text);
// 	range.insertNode(textNode);
// 	range.setStartAfter(textNode);
// 	sel.removeAllRanges();
// 	sel.addRange(range);
// };

export const insertHtmlAtCaret = (html) => {
	let sel, range;

	//window.activeEditor.focus();

	sel = window.getSelection();
	if (sel.getRangeAt && sel.rangeCount) {
		range = sel.getRangeAt(0);
		range.deleteContents();

		let el = document.createElement('div');
		el.innerHTML = html;

		let frag = document.createDocumentFragment(),
			node,
			lastNode;
		while ((node = el.firstChild)) {
			lastNode = frag.appendChild(node);
		}
		range.insertNode(frag);

		//Preserve the selection
		if (lastNode) {
			range = range.cloneRange();
			range.setStartAfter(lastNode);
			range.collapse(true);
			sel.removeAllRanges();
			sel.addRange(range);
		}

		return window.activeEditor.innerHTML;
	}
};

export const escapeRegExp = (string = '') => {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const isNumeric = (n) => {
	return !isNaN(parseFloat(n)) && isFinite(n);
};

export const preventBrowserReload = () => {
	window.onbeforeunload = function () {
		return 'There are unsaved segments. Leave Page?';
	};
};

export const allowBrowserReload = () => {
	window.onbeforeunload = null;
};

export const isLowerCase = (str) => {
	return str == str.toLowerCase() && str != str.toUpperCase();
};

export const selectionToggleCase = (e, dispatch) => {
	e.preventDefault();

	let sel = window.getSelection();
	const elSelection = sel.anchorNode.parentElement.closest('.gridRow');

	let range = sel.getRangeAt(0);
	let contents = range.cloneContents().childNodes;
	let frag = document.createDocumentFragment();

	if (sel.getRangeAt && sel.rangeCount) {
		//find 1st letter
		var tstChar = sel.toString().match(/\w/);
		let caseFunction = 'upper';

		if (tstChar) caseFunction = isLowerCase(tstChar[0]) ? 'upper' : 'lower';

		range.deleteContents();
		contents.forEach(function (itm) {
			if (itm.nodeType == 3) {
				itm.textContent = toToggleCase(itm.textContent);
			}

			frag.appendChild(itm.cloneNode(true));
		});

		range.insertNode(frag);

		/* if (window.activeEditor && document.activeElement) {
			setTimeout(() => {
				dispatch({
					type: 'traEditor/updateSegmentText',
					payload: {
						segID: elSelection.getAttribute('data-segid'),
						translationText: window.activeEditor.getText(
							0,
							window.activeEditor.getLength() - 1
						),
					},
				});
			}, 1);
		} */
	}
};

function toToggleCase(str) {
	let result = "";

	for(let char of str) {
		result += (char === char.toUpperCase()) ? char.toLowerCase() : char.toUpperCase();

	}

	return result;
}

export const selectionToCase = (e, caseToUse, dispatch) => {
	e.preventDefault();

	let sel = window.getSelection();
	const elSelection = sel.anchorNode.parentElement.closest('.gridRow');

	if (sel.toString().length === 0) {
		// Take text of active segment
		// Transform whole text
		let wholeSegmentText = window.activeEditor.getText(
			0,
			window.activeEditor.getLength() - 1
		);

		if (caseToUse == 'upper') {
			wholeSegmentText = wholeSegmentText.toUpperCase();
		}
		if (caseToUse == 'lower') {
			wholeSegmentText = wholeSegmentText.toLowerCase();
		}
		if (caseToUse == 'titleCase') {
			wholeSegmentText = capitaliseEachWord(wholeSegmentText);
		}
		if (caseToUse == 'sentenceCase') {
			wholeSegmentText = sentenceCase(wholeSegmentText);
		}
	}

	let range = sel.getRangeAt(0);
	let contents = range.cloneContents().childNodes;
	let frag = document.createDocumentFragment();

	let fullString = '';

	if (sel.getRangeAt && sel.rangeCount) {
		range.deleteContents();
		contents.forEach(function (itm) {
			if (itm.nodeType == 3) {
				if (caseToUse == 'upper')
					itm.textContent = itm.textContent.toString().toUpperCase();

				if (caseToUse == 'lower')
					itm.textContent = itm.textContent.toString().toLowerCase();
			}

			// if its a case where the selection must be handled as a single string, then
			// we add every itm in a single string and we omit appending a Child to the docFragment
			// the built fullString var is handled after this loop
			if (caseToUse == 'titleCase' || caseToUse == 'sentenceCase') {
				fullString += itm.textContent.toString();
			} else {
				frag.appendChild(itm.cloneNode(true));
			}
		});

		// we handle the capitalisation using the fullString var we built previously in the range.contents.forEach
		if (caseToUse == 'titleCase' || caseToUse == 'sentenceCase') {
			let newString = '';
			if (caseToUse == 'titleCase') newString = capitaliseEachWord(fullString);

			if (caseToUse == 'sentenceCase') newString = sentenceCase(fullString);

			// create a new text node and append a clone of it to the fragment
			let newTextNode = document.createTextNode(newString);
			frag.appendChild(newTextNode.cloneNode(true));
		}

		range.insertNode(frag);

		if (window.activeEditor && document.activeElement) {
			setTimeout(() => {
				dispatch({
					type: 'traEditor/updateSegmentText',
					payload: {
						segID: elSelection.getAttribute('data-segid'),
						translationText: window.activeEditor.getText(
							0,
							window.activeEditor.getLength() - 1
						),
					},
				});
			}, 1);
		}
	}
};

export const toSentenceCase = (e) => {
	e.preventDefault();

	let sel = window.getSelection();
	const elSelection = sel.anchorNode.parentElement.closest('.gridRow');

	let range = sel.getRangeAt(0);
	let contents = range.cloneContents().childNodes;
	let frag = document.createDocumentFragment();

	if (sel.getRangeAt && sel.rangeCount) {
		//find 1st letter
		var tstChar = sel.toString().match(/\w/);
		let caseFunction = 'upper';

		if (tstChar) caseFunction = isLowerCase(tstChar[0]) ? 'upper' : 'lower';

		range.deleteContents();
		contents.forEach(function (itm) {
			if (itm.nodeType == 3) {
				itm.textContent = toSentenceCaseExceptTags(itm.textContent);
			}

			frag.appendChild(itm.cloneNode(true));
		});

		range.insertNode(frag);
	}
}

function toSentenceCaseExceptTags(str) {
	return str.replace(/([^<.!?]*[^<.!?]*[.!?])\s*/g, function(match) {
		let i = 0;
		const len = match.length;
		while (i < len && /[<]/.test(match[i])) {
			i++;
		}
		return (i > 0 ? match.substring(0, i) : "") + match[i].toUpperCase() + match.substring(i + 1).toLowerCase();
	});
}

export const lowerCase = (e) => {
	e.preventDefault();

	let sel = window.getSelection();
	const elSelection = sel.anchorNode.parentElement.closest('.gridRow');

	let range = sel.getRangeAt(0);
	let contents = range.cloneContents().childNodes;
	let frag = document.createDocumentFragment();

	if (sel.getRangeAt && sel.rangeCount) {
		//find 1st letter
		var tstChar = sel.toString().match(/\w/);
		let caseFunction = 'upper';

		if (tstChar) caseFunction = isLowerCase(tstChar[0]) ? 'upper' : 'lower';

		range.deleteContents();
		contents.forEach(function (itm) {
			if (itm.nodeType == 3) {
				itm.textContent = toLowerCaseExceptTags(itm.textContent);
			}

			frag.appendChild(itm.cloneNode(true));
		});

		range.insertNode(frag);
	}
}

function toLowerCaseExceptTags(str) {
	return str.replace(/([^<]+)(<[^>]+>|$)/g, function(_, text, tag) {
		return text.toLowerCase() + (tag || '');
	});
}

export const upperCase = (e) => {
	e.preventDefault();

	let sel = window.getSelection();
	const elSelection = sel.anchorNode.parentElement.closest('.gridRow');

	let range = sel.getRangeAt(0);
	let contents = range.cloneContents().childNodes;
	let frag = document.createDocumentFragment();

	if (sel.getRangeAt && sel.rangeCount) {
		//find 1st letter
		var tstChar = sel.toString().match(/\w/);
		let caseFunction = 'upper';

		if (tstChar) caseFunction = isLowerCase(tstChar[0]) ? 'upper' : 'lower';

		range.deleteContents();
		contents.forEach(function (itm) {
			if (itm.nodeType == 3) {
				itm.textContent = toUpperCaseExceptTags(itm.textContent);
			}

			frag.appendChild(itm.cloneNode(true));
		});

		range.insertNode(frag);
	}
}

function toUpperCaseExceptTags(str) {
	return str.replace(/([^<]+)(<[^>]+>|$)/g, function(_, text, tag) {
		return text.toUpperCase() + (tag || '');
	});
}

export const capitalizeEachWord = (e) => {
	e.preventDefault();

	let sel = window.getSelection();
	const elSelection = sel.anchorNode.parentElement.closest('.gridRow');

	let range = sel.getRangeAt(0);
	let contents = range.cloneContents().childNodes;
	let frag = document.createDocumentFragment();

	if (sel.getRangeAt && sel.rangeCount) {
		//find 1st letter
		var tstChar = sel.toString().match(/\w/);
		let caseFunction = 'upper';

		if (tstChar) caseFunction = isLowerCase(tstChar[0]) ? 'upper' : 'lower';

		range.deleteContents();
		contents.forEach(function (itm) {
			if (itm.nodeType == 3) {
				itm.textContent = capitalizeExceptTags(itm.textContent);
			}

			frag.appendChild(itm.cloneNode(true));
		});

		range.insertNode(frag);
	}
}

function capitalizeExceptTags(str) {
	return str.replace(/\b(\w+)\b/g, function(word){
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	});
}

function capitaliseEachWord(str) {
	let x = str;
	x = x.toLowerCase().split(' ');
	for (var i = 0; i < x.length; i++) {
		x[i] = x[i].charAt(0).toUpperCase() + x[i].slice(1);
	}
	return x.join(' ');
}

function sentenceCase(str) {
	str = str.toLowerCase();
	str = str.toLowerCase().split(' ');
	str[0] = str[0].charAt(0).toUpperCase() + str[0].slice(1);
	return str.join(' ');
}

export const getSelectionText = () => {
	var text = '';
	if (window.getSelection) {
		text = window.getSelection().toString();
	} else if (document.selection && document.selection.type != 'Control') {
		text = document.selection.createRange().text;
	}
	return text;
};

export const escapeHTML = (str) =>
	str.replace(
		/[&<>"]/g,
		(tag) =>
			({
				'&': '&amp;',
				'<': '&lt;',
				'>': '&gt;',
				'"': '&quot;',
				// "'": '&#39;',
			}[tag])
	);

export const toggleFormatsToSelection = (formats) => {
	if (!window?.activeEditor || !formats) {
		return;
	}

	const selection = window.activeEditor.getSelection();
	const selectionFormats = window.activeEditor.getFormat(selection);

	//console.log(selectionFormats);

	if (formats == 'italic') {
		window.activeEditor.format('saitalic', !selectionFormats?.saitalic, 'user');
	}

	if (formats == 'bold') {
		window.activeEditor.format('sastrong', !selectionFormats?.sastrong, 'user');
	}

	if (formats == 'underline') {
		window.activeEditor.format(
			'saunderline',
			!selectionFormats?.saunderline,
			'user'
		);
	}

	if (formats == 'superscript') {
		window.activeEditor.format('sasup', !selectionFormats?.sasup, 'user');
	}

	if (formats == 'subscript') {
		window.activeEditor.format('sasub', !selectionFormats?.sasub, 'user');
	}
};

export const getSimilarFontByName = (fontName) => {
	return 'sans-serif';
};
