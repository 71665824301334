export const DEFAULT_COMMENT_TYPES = ['For Client', 'For PM'];

export const TASK_STATUS = {
	_NEW: 'New',
	ACCEPTED: 'Accepted',
	DECLINED: 'Declined',
	COMPLETED: 'Completed',
	CANCELLED: 'Cancelled',
	EMAILED: 'Emailed',
	AUTO_EMAILED: 'Auto-Emailed',
	PRETRANSLATED: 'Pretranslated',
	// SPLIT: 'Split',
	PARALLEL: 'Parallel with Next Step',
	IN_PROGRESS: 'In Progress',
	REOPEN_COMPLETED: 'Reopen Completed',
	READY_FOR_DELIVERY: 'Ready for Delivery',
};

export const USER_ROLES = {
	TRANSLATOR: 'Translator',
	OTHER: 'Other',
};

export const TM_TYPES = {
	MASTER: 'Master',
	WORKING: 'Working',
	PROJECT: 'Project',
	ALIGNMENT: 'Alignment',
};

export const TB_TYPES = {
	MASTER: 'Master',
	WORKING: 'Working',
	PROJECT: 'Project',
	ALIGNMENT: 'Alignment',
};

export const PROJECT_STATUS = {
	IN_PROGRESS: 'In Progress',
	COMPLETED: 'Completed',
	CANCELLED: 'Cancelled',
};

export const SEGMENT_STATUS = {
	DRAFT: 'draft',
	CONFIRMED: 'confirmed',
	REJECTED: 'rejected',
};
