import _ from 'lodash';
import SegmentsMap from 'libs/segmentsMap';

import SegmentOperations from './segmentOperations';

const defSegmentsFilters = {
	text: {
		source: '',
		target: '',
	},
	status: {
		active: false,
		notEdited: false,
		edited: false,
		confirmed: false,
		notConfirmed: false,
		locked: false,
		notLocked: false,
		review: false,
		notReview: false,
		empty: false,
		notEmpty: false,
	},
	sort: {
		sourceAZ: false,
		sourceZA: false,
		targetAZ: false,
		targetZA: false,
		shortestFirstSource: false,
		longestFirstSource: false,
		shortestFirstTarget: false,
		longestFirstTarget: false,
		lowestMatchFirst: false,
		highestMatchFirst: false,
	},
	tags: {
		withTags: false,
		withoutTags: false,
	},
	errors: {
		withErrorsCritical: false,
		withErrors: false,
		withoutErrors: false,
	},
	lastInput: {
		manualInput: false,
		MT: false,
		fuzzy: false,
		fuzzyValues: [50, 102],
	},
	comments: {
		withComments: false,
		withoutComments: false,
		commentsFilters: [],
		resolved: false,
		notResolved: false,
	},
	sourceText: {
		showRepException: false,
		targetSameAsSource: false,
		showRepeatedSegments: false,
		hideNumbersOnly: false,
		showNumbersOnly: false,
		showSecondRepetitions: false,
	},
	TMs: {
		unstoredCheckbox: false,
		unstoredSegments: {},
	},
	editedSegments: [],
	isTyping: false
};

const dialogs = {
	exportFile: {
		show: false,
		data: null,
	},
	gotoSegment: {
		show: false,
		data: null,
	},
	addTerm: {
		show: false,
		data: null,
	},
	preTranslate: {
		show: false,
		data: null,
	},
	runQA: {
		show: false,
		data: null,
	},
	specialChars: {
		show: false,
		data: {
			specialCharsLastPosition: {
				x: typeof window == 'undefined' ? 20 : window.outerWidth - 500 - 400,
				y: 150,
				width: 505,
				height: 280,
			},
			specialChars: {
				commons: [],
				lang: [],
			},
		},
	},
	QAResults: {
		show: false,
		data: null,
	},
	commentBox: {
		show: false,
		data: null,
	},
	segmentsFilters: {
		show: false,
	},
	searchAndReplace: {
		show: false,
		data: null,
	},
	finishTranslation: {
		show: false,
		data: null,
	},
	uploadBilingual: {
		show: false,
		data: null,
	},
	createPlaceholder: {
		show: false,
		data: null,
	},
	addRule: {
		show: false,
		data: null,
	},
	exportBilingual: {
		show: false,
		data: null,
	},
	TMsTBsComments: {
		show: false,
		data: null,
	},
	virtualKeyboard: {
		show: false,
		data: null,
	},
};

const initialState = {
	//data
	task: {},

	commentsBox: [],

	// Segments filters
	segmentsFilters: _.cloneDeep(defSegmentsFilters),

	//UI
	loading: false,
	editorModeColumns: false,
	expandTags: false,
	showHiddenChars: false,
	btnItalicsActive: false,
	btnBoldActive: false,

	dialogs,

	// Search & Replace
	searchAndReplace: {
		find: '',
		replace: '',
		searchIn: 'trg',
	},
	searchReplaceStack: null,

	//segments
	arSegments: new SegmentsMap(),
	arDisplaySegments: new SegmentsMap(),
	repetitionsCache: {},

	activeSegment: null,

	activeSuggestion: null,

	activeHistory: null,

	forceRender: 0,

	allSegmentsChecked: false,
	filtersActive: false,
	autoTranslating: false,
	arAutoTransSegmentsQueued: 0,
	arAutoTransSegmentsDone: 0,
	areAllConfirmed: false,
	finishDialogAlreadyShown: false,
	batchSavingMsg: 'Processing...',
	isBatchSaving: false,

	runningQA: false,

	indexToGo: null,
	scrollToAlignment: 'auto',

	sessionDownVotedSuggestions: [],

	concordance: {
		isLoading: false,
		results: null,
		term: '',
		matchType: 'exact',
		searchTo: 'src',
		searchAllTMs: false,
		activeResult: null,
	},

	synonyms: {
		isLoading: false,
		results: [],
		term: '',
	},

	currentUser: null,
	historyResultsCount: 0,
	changesResultsCount: 0,

	showAvailableOnly: false,

	caseSensitiveSearch: false,
	wholeWordSearch: false,
	regexSearch: false,
	changes: []
};

const TranslationReducer = (state = initialState, action) => {
	const { type, payload } = action;

	let tmpState;
	let thisSegment;

	switch (type) {
		case 'traEditor/setProp':
			return { ...state, ...payload };

		case 'traEditor/reRender':
			return { ...state, forceRender: new Date() };

		case 'traEditor/initSegments':
			return SegmentOperations.initSegments(state, payload);

		case 'traEditor/updateTask':
			return {
				...state,
				task: {
					...state.task,
					...payload,
				},
			};

		case 'traEditor/setSaving':
			return SegmentOperations.setSaving(state, payload);

		case 'traEditor/updateSegment':
			return SegmentOperations.updateSegment(state, payload);
		case 'traEditor/updateSegments':
			return SegmentOperations.updateSegments(state, payload);
		case 'traEditor/cleanQaResults':
			return SegmentOperations.cleanQaResults(state);

		case 'traEditor/toggleEditorRowsMode':
			return {
				...state,
				editorModeColumns: !state.editorModeColumns,
			};

		case 'traEditor/setActiveSegment':
			return SegmentOperations.setActiveSegment(state, payload);
		case 'traEditor/setSegmentToSelectionChecked':
			return SegmentOperations.setSegmentToSelectionChecked(state, payload);
		case 'traEditor/setSegmentCheckedToRange':
			return SegmentOperations.setSegmentCheckedToRange(state, payload);

		case 'traEditor/setBatchSaving':
			return {
				...state,
				batchSavingMsg: action?.payload?.msg
					? action?.payload?.msg
					: 'Processing...',
				isBatchSaving: action?.payload?.show ?? false,
			};

		//--- Selection ---
		case 'traEditor/selectAll':
			return SegmentOperations.selectSegments(state, 'all');

		case 'traEditor/select':
			return SegmentOperations.selectSegments(state, payload);

		//--- Segment Actions ---
		case 'traEditor/copySourceToTarget':
			return SegmentOperations.copySourceToTarget(state);
		case 'traEditor/deleteTargetText':
			return SegmentOperations.deleteTargetText(state);

		case 'traEditor/updateSegmentText':
			tmpState = {
				...state,
			};

			if (tmpState?.isTyping === true) {
				return state;
			}

			thisSegment = {
				...tmpState.arSegments.getSegment(payload.segID),
				...payload,
			};

			// console.log(
			// 	'before',
			// 	thisSegment.translationText.match(/<sa(.*?)>(\s+)/)
			// );

			//WHAT IS THIS?
			thisSegment.translationText = thisSegment.translationText.replace(
				/(<sa[^>]*>)(\s+)/,
				'$2$1'
			);
			thisSegment.translationText = thisSegment.translationText.replace(
				/(\s+)(<\/sa[^>]*>)/,
				'$2$1'
			);
			//console.log('after', thisSegment.translationText);

			//if triggerred save already
			if (!thisSegment.forSave) {
				thisSegment.status = 'draft';
				thisSegment.nextStatus = 'draft';
			}

			// If new translation text is completely empty, clear metadata as well
			if (thisSegment.translationText === '') {
				thisSegment.matchType = '';
				thisSegment.matchScore = '';
			}

			tmpState.arSegments = tmpState.arSegments.setSegment(
				payload.segID,
				thisSegment
			);

			if (tmpState.arDisplaySegments.hasSegment(payload.segID)) {
				tmpState.arDisplaySegments = tmpState.arDisplaySegments.setSegment(
					payload.segID,
					{ ...thisSegment }
				);
			}

			//chk for reps
			if (thisSegment?.sameAs > 0) {
				const { repetitionsCache } = tmpState;

				tmpState.arSegments =
					SegmentOperations.updateTranslationTextRepetitions(
						repetitionsCache,
						tmpState.arSegments,
						thisSegment
					);

				tmpState.arDisplaySegments =
					SegmentOperations.updateTranslationTextRepetitions(
						repetitionsCache,
						tmpState.arDisplaySegments,
						thisSegment
					);
			}

			return tmpState;

		case 'traEditor/reset':
			return null;

		case 'traEditor/clearSegmentsFilters':
			tmpState = {
				...state,
				segmentsFilters: {
					...defSegmentsFilters,
				},
				filtersActive: false,
				caseSensitiveSearch: false,
				wholeWordSearch: false,
				regexSearch: false,
				arDisplaySegments: initialState.arDisplaySegments,
			};

			return tmpState;
		case 'traEditor/setFilters':
			tmpState = {
				...state,
				segmentsFilters: {
					...state.segmentsFilters,
				},
			};

			const activeFilters = tmpState.segmentsFilters;

			// Text filtering & fuzzy values are not checkboxes, so skip them
			if (
				payload?.type !== 'text' &&
				payload?.type !== 'fuzzy' &&
				payload?.type !== 'select' &&
				payload?.type !== 'fetchedData' &&
				payload?.type !== 'editedSegments'
			) {
				activeFilters[payload?.type][payload?.name] = payload?.checked;
			}

			if (payload?.type === 'text') {
				const { type, ...rest } = payload;

				tmpState = {
					...state,
					segmentsFilters: {
						...state.segmentsFilters,
						text: {
							...state.segmentsFilters.text,
							...rest,
						},
					},
				};

				const newTmpState = {
					...tmpState,
					actSegment: {}
				};

				const newdefSegmentsFilters = {
					...defSegmentsFilters,
					actSegment: {}
				};

				const areSegmentsFiltersDirty =
					JSON.stringify(newTmpState) != JSON.stringify(newdefSegmentsFilters);

				tmpState.filtersActive = areSegmentsFiltersDirty;
			}

			if (payload?.type === 'fuzzy') {
				activeFilters.lastInput.fuzzyValues = payload?.fuzzyValues;
			}

			if (payload?.type === 'select') {
				activeFilters.comments.commentsFilters = payload?.commentsFilters;
			}

			if (payload?.type === 'fetchedData') {
				activeFilters.TMs.unstoredSegments = payload?.value;
			}

			if (payload?.type === 'editedSegments') {
				activeFilters.editedSegments = payload?.value;
			}

			return tmpState;
		case 'traEditor/setSort':
			const { segmentsFilters, filtersActive, ...rest } = state;

			const newSegmentsFilters = {
				...segmentsFilters,
				sort: {
					...segmentsFilters.sort,
					...payload,
				},
			};

			return {
				...rest,
				filtersActive:
					JSON.stringify(newSegmentsFilters) !=
					JSON.stringify(defSegmentsFilters),
				segmentsFilters: newSegmentsFilters,
			};
		case 'traEditor/filterAllSegments':
			return SegmentOperations.filterAllSegments(state, defSegmentsFilters);

		case 'traEditor/clearSearchAndReplace':
			return SegmentOperations.searchAndReplaceClear(state);

		case 'traEditor/searchAndReplaceDoAction':
			return SegmentOperations.searchAndReplaceDoAction(state, payload);

		case 'traEditor/undoSearchAndReplace':
			return SegmentOperations.undoSearchAndReplace(state, payload);

		case 'traEditor/showDialog':
			tmpState = {
				...state,
				dialogs: { ...state.dialogs },
			};

			tmpState.dialogs[payload.key] = {
				show: true,
				data: payload?.data
					? payload.data
					: tmpState.dialogs[payload.key]?.data,
			};

			return tmpState;

		case 'traEditor/hideDialog':
			tmpState = {
				...state,
				dialogs: { ...state.dialogs },
			};

			tmpState.dialogs[payload.key] = {
				show: false,
				data: tmpState.dialogs[payload.key]?.data,
			};

			return tmpState;

		case 'traEditor/runQATask':
			return { ...state, runningQA: true };
		case 'traEditor/stopQATask':
			return { ...state, runningQA: false };
		case 'traEditor/updateSegmentQAIgnore':
			return SegmentOperations.updateQAIngoreState(state, payload);

		case 'traEditor/goToSegment':
			return {
				...state,
				indexToGo: payload.indexToGo,
				scrollToAlignment: payload.scrollToAlignment,
			};
		case 'traEditor/resetGoToSegment':
			return {
				...state,
				indexToGo: null,
				scrollToAlignment: 'auto',
			};

		//---- Suggestions ---
		case 'traEditor/setActiveSuggestion':
			return {
				...state,
				activeSuggestion: payload,
			};

		case 'traEditor/updateSessionDownVotes':
			return {
				...state,
				sessionDownVotedSuggestions: [
					...state.sessionDownVotedSuggestions,
					payload,
				],
			};

		// Concordance
		case 'traEditor/setConcordanceProp':
			return {
				...state,
				concordance: {
					...state.concordance,
					...payload,
				},
			};

		// Synonym
		case 'traEditor/setSynonymsProp':
			return {
				...state,
				synonyms: {
					...state.synonyms,
					...payload,
				},
			};

		case 'traEditor/updateTranslatorNotes':
			tmpState = {
				...state,
				task: { ...state.task },
			};

			tmpState.task.translatorNotes = payload;

			return tmpState;

		case 'traEditor/setChangesResultsCount':
			return {
				...state,
				changesResultsCount: payload,
			};

		case 'traEditor/setChanges':
			return {
				...state,
				changes: payload,
			};

		case 'traEditor/setUndo':
			return {
				...state,
				undo: payload,
			};

		case 'traEditor/setRedo':
			return {
				...state,
				redo: payload,
			};

		case 'traEditor/setHistoryResultsCount':
			return {
				...state,
				historyResultsCount: payload,
			};

		case 'traEditor/setUndoResultsCount':
			return {
				...state,
				undoResultsCount: payload,
			};

		case 'traEditor/setRedoResultsCount':
			return {
				...state,
				redoResultsCount: payload,
			};

		case 'traEditor/setCommentsResultsCount':
			return {
				...state,
				commentsResultsCount: payload,
			};

		case 'traEditor/setCommentsBox':
			return {
				...state,
				commentsBox: payload,
			};

		case 'traEditor/updateTaskPreferences':
			tmpState = {
				...state,
			};

			const taskPreferences = tmpState?.task?.preferences;
			let newPreferences = {};

			if (!taskPreferences) {
				newPreferences = {
					...taskPreferences,
					[payload.preferenceKey]: true,
				};
			} else {
				newPreferences = {
					...taskPreferences,
					[payload.preferenceKey]: !taskPreferences[payload.preferenceKey],
				};
			}

			tmpState.task = {
				...tmpState.task,
				preferences: newPreferences,
			};

			return tmpState;

		case 'traEditor/setActiveHistory':
			return {
				...state,
				activeHistory: payload,
			};

		case 'traEditor/setActiveUndo':
			return {
				...state,
				activeUndo: payload,
			};

		case 'traEditor/setActiveRedo':
			return {
				...state,
				activeRedo: payload,
			};

		case 'traEditor/updateSegmentResolvedComments':
			return SegmentOperations.updateSegmentResolvedComments(state, payload);

		case 'traEditor/toggleShowAvailableOnly':
			const displaySegments = !state.showAvailableOnly
				? state.arDisplaySegments.filter(({ available }) => available)
				: state.arSegments;

			return {
				...state,
				showAvailableOnly: !state.showAvailableOnly,
				arDisplaySegments: displaySegments,
			};

		default:
			return state;
	}
};

export default TranslationReducer;
