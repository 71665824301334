import { useMemo } from 'react';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import SegmentsMap from 'libs/segmentsMap';

import UserReducer from './userReducer';
import TMEditorReducer from 'views/translationmemories/editor/reducer';
import TBEditorReducer from 'views/termbases/editor/reducer';
import TranslationReducer from 'views/translationEditor/translationReducer';
import LangsReducer from './langsReducer';
import RSEditorReducer from 'views/rulesets/editor/reducer';
import SettingsReducer from 'views/settings/settingsReducer';

let store;

const initialState = {};

function initStore(preloadedState = initialState) {
	return createStore(
		combineReducers({
			user: UserReducer,
			langs: LangsReducer,
			tmEditor: TMEditorReducer,
			tbEditor: TBEditorReducer,
			traEditor: TranslationReducer,
			rsEditor: RSEditorReducer,
			settings: SettingsReducer
		}),
		preloadedState,
		composeWithDevTools(applyMiddleware(thunk))
	);
}

export const initializeStore = (preloadedState) => {
	let _store = store ?? initStore(preloadedState);

	// After navigating to a page with an initial Redux state, merge that state
	// with the current state in the store, and create a new store
	if (preloadedState && store) {
		_store = initStore({
			...store.getState(),
			...preloadedState,
		});
		// Reset the current store
		store = undefined;
	}

	// For SSG and SSR always create a new store
	if (typeof window === 'undefined') return _store;
	// Create the store once in the client
	if (!store) store = _store;

	return _store;
};

export function useStore(initialState) {
	const store = useMemo(() => initializeStore(initialState), [initialState]);
	return store;
}

export const serializeState = (state) => {
	state.traEditor.arSegments = state.traEditor.arSegments instanceof Map ? state.traEditor.arSegments.toObject() : {};
	state.traEditor.arDisplaySegments =
		state.traEditor.arDisplaySegments instanceof Map ? state.traEditor.arDisplaySegments.toObject() : {};

	return state;
};

export const deserializeState = (state) => {
	state.traEditor.arSegments = new SegmentsMap(state.traEditor.arSegments);
	state.traEditor.arDisplaySegments = new SegmentsMap(
		state.traEditor.arDisplaySegments
	);

	return state;
};
