import getConfig from 'next/config';
import fetch from 'libs/fetchWithAuth';
const {
	publicRuntimeConfig: { FRONTEND_URL },
} = getConfig();

const _baseUrl = FRONTEND_URL + '/api/ext/segments';

class TranslationSegments {
	static getForTask(data, req) {
		return fetch(
			`${FRONTEND_URL}/api/ext/tasks/${data.id}/segments`,
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static getBySegmentIdAndTaskId(segmentId, taskId, req) {
		return fetch(
			`/api/ext/segments/get-suggestions/${segmentId}/${taskId}`,
			{
				method: 'GET',
			},
			req
		);
	}

	static getForTaskByIds(data, req) {
		return fetch(
			`${FRONTEND_URL}/api/ext/tasks/segments`,
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static getSegmentsOnRequest(segments, req) {
		return fetch(
			`${FRONTEND_URL}/api/ext/tasks/segments-on-request`,
			{
				method: 'POST',
				body: JSON.stringify({
					segments
				}),
			},
			req
		);
	}

	static setLocked(data, req) {
		return fetch(
			_baseUrl + '/set-locked',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static saveSegment(data, req) {
		return fetch(
			_baseUrl + '/save-segment',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static saveAll(data, req) {
		return fetch(
			_baseUrl + '/save-all',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static saveAllSegments(data, req) {
		return fetch(
			_baseUrl + '/save-all-filter',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static getCommentsBySegment(segmentID, targetLang, commentsFilter, req) {
		return fetch(
			_baseUrl + `/comments/${segmentID}/${targetLang}`,
			{
				method: 'POST',
				body: JSON.stringify({
					filterBy: commentsFilter,
				}),
			},
			req
		);
	}

	static saveComment(data, req) {
		return fetch(
			_baseUrl + '/comments',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static replyToComment(data, req) {
		return fetch(
			_baseUrl + '/comments/reply',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static setResolvedComment(data, req) {
		return fetch(
			_baseUrl + '/comments/set-resolved',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static deleteComment(data, req) {
		return fetch(
			_baseUrl + '/comments/delete',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static updateComment(data, req) {
		return fetch(
			_baseUrl + '/comments/update',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static getHistoryAndChanges(data, req) {
		return fetch(
			_baseUrl + '/get-changes-history',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static getHistoryAndChangesInterupt(data, signal, req) {
		return fetch(
			_baseUrl + '/get-changes-history',
			{
				method: 'POST',
				body: JSON.stringify(data),
				signal
			},
			req
		);
	}

	static getHistoryAndChangesByType(type, data, signal, req) {
		return fetch(
			_baseUrl + '/get-changes-history/' + type,
			{
				method: 'POST',
				body: JSON.stringify(data),
				signal: signal
			},
			req
		);
	}

	static toggleRepetition(data, req) {
		return fetch(
			_baseUrl + '/toggle-all-repetitions',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static getHistoryForUndo(data, req) {
		return fetch(
			_baseUrl + '/get-history-undo',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static getHistoryForRedo(data, req) {
		return fetch(
			_baseUrl + '/get-history-redo',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static getHistory(data, req) {
		return fetch(
			_baseUrl + '/history',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static getChanges(data, req) {
		return fetch(
			_baseUrl + '/get-changes',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static getSuggestions(data, req) {
		return fetch(
			_baseUrl + '/get-suggestions',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static getSuggestionsInterupt(data, signal, req) {
		return fetch(
			_baseUrl + '/get-suggestions',
			{
				method: 'POST',
				body: JSON.stringify(data),
				signal: signal,
			},
			req
		);
	}

	static repetitionSetSync(data, req) {
		return fetch(
			_baseUrl + '/repetition-set-sync',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static firstRepetitionSetSync(data, req) {
		return fetch(
			_baseUrl + '/first-repetition-set-sync',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static updateSource(data, req) {
		return fetch(
			_baseUrl + '/update-source',
			{
				method: 'POST',
				body: JSON.stringify(data),
			},
			req
		);
	}

	static getTranslationsByFileAndOrder(fileID, order, req) {
		return fetch(_baseUrl + `/by-file-and-order/${fileID}/${order}/alt-trans`, {}, req);
	}

	static getCommentsByHash(hash, req) {
		return fetch(`${_baseUrl}/comments/get-by-hash/${hash}`, {}, req);
	}

	static getByIdAndTargetLang(segmentID, targetLang, req) {
		return fetch(`${_baseUrl}/${segmentID}/${targetLang}`, {}, req);
	}

	static undo(taskID, segmentID, req) {
		return fetch(`${_baseUrl}/undo/${taskID}/${segmentID}`, {}, req);
	}

	static redo(taskID, segmentID, req) {
		return fetch(`${_baseUrl}/redo/${taskID}/${segmentID}`, {}, req);
	}

	static joinSegments(arSegmentsToJoin, taskID, req) {
		return fetch(
			`${_baseUrl}/join`,
			{
				method: 'POST',
				body: JSON.stringify({
					arSegments: arSegmentsToJoin,
					taskID,
				}),
			},
			req
		);
	}

	static splitSegment(splitPos, segmentID, taskID, req) {
		return fetch(
			`${_baseUrl}/split`,
			{
				method: 'POST',
				body: JSON.stringify({
					splitPos,
					segmentID,
					taskID,
				}),
			},
			req
		);
	}
}

export default TranslationSegments;
