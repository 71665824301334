import ItemsOperation from './itemsOperations';

const defFilters = {
	src: '',
	trg: '',
	fileNames: [],
	usersCreated: [],
	usersUpdated: [],
	sortSrc: null,
	sortTrg: null,
};

const dialogs = {
	searchAndReplace: {
		show: false,
	},
	addLangs: {
		show: false,
	},
	removeLangs: {
		show: false,
	},
};

const initialState = {
	//data
	info: {},
	filterParams: {},
	terms: [],
	termsCount: 0,
	startOfs: 0,
	displayLang1: '',
	displayLang2: '',

	//filters
	filters: { ...defFilters },

	//UI
	loading: false,
	activeItem: null,
	forceRender: 0,

	//actions
	filtersVisible: false, //dialog
	filtersActive: false, //are filters applied to selection
	displayHiddenChars: false,
	displayTags: false,
	expandTags: false,

	// Refactored dialogs
	dialogs,

	// Search & Replace
	searchAndReplace: {
		find: '',
		replace: '',
		searchIn: 'trg',
	},
	searchReplaceStack: null,
};

const TBReducer = (state = initialState, action) => {
	const { payload, type } = action;

	let tmpState;

	switch (type) {
		case 'tbEditor/setInfo':
			return {
				...state,
				...initialState,
				info: { ...payload },
			};

		case 'tbEditor/setFilterParams':
			return {
				...state,
				filterParams: { ...payload },
			};

		case 'tbEditor/setFilter':
			tmpState = {
				...state,
				filters: {
					...state.filters,
					...payload,
				},
			};

			tmpState.filtersActive =
				JSON.stringify(tmpState.filters) != JSON.stringify(defFilters);
			return tmpState;
		case 'tbEditor/clearFilters':
			return {
				...state,
				filters: { ...defFilters },
				filtersActive: false,
			};

		case 'tbEditor/setProperty':
			return {
				...state,
				...payload,
			};

		case 'tbEditor/setActiveItem':
			return ItemsOperation.setActiveItem(state, payload);
		case 'tbEditor/setItemToChecked':
			return ItemsOperation.setItemToChecked(state, payload);
		case 'tbEditor/setItemToCheckedRange':
			return ItemsOperation.setItemToCheckedRange(state, payload);

		case 'tbEditor/updateTerms':
			return ItemsOperation.updateTerms(state, payload);

		case 'tbEditor/setTermsCount':
			//reset terms if not same count
			if (state.termsCount != payload) {
				state = {
					...state,
					termsCount: payload,
					terms: new Array(payload).fill(null),
				};
			}
			return state;

		case 'tbEditor/setTerms':
			const { startOfs, arTerms } = payload;
			tmpState = {
				...state,
				terms: [...state.terms],
			};

			arTerms.map((T, i) => {
				T._source.id = T._id;
				T._source.checked = false;

				const termIdx = parseInt(startOfs) + i;
				T._source.idx = termIdx;

				T._source.hasChanges = false;

				tmpState.terms[termIdx] = T._source;
			});

			return tmpState;
			break;
		case 'tbEditor/updateTerm':
			return ItemsOperation.updateTerm(state, payload);
		case 'tbEditor/removeTerms':
			return ItemsOperation.removeTerms(state, payload);

		case 'tbEditor/reset':
			return null;

		case 'tbEditor/clearSearchAndReplace':
			return ItemsOperation.searchAndReplaceClear(state);

		case 'tbEditor/searchAndReplaceDoAction':
			return ItemsOperation.searchAndReplaceDoAction(state, payload);

		case 'tbEditor/undoSearchAndReplace':
			return ItemsOperation.undoSearchAndReplace(state, payload);

		case 'tbEditor/showDialog':
			tmpState = {
				...state,
				dialogs: { ...state.dialogs },
			};

			tmpState.dialogs[payload.key] = {
				show: true,
				data: payload?.data
					? payload.data
					: tmpState.dialogs[payload.key]?.data,
			};

			return tmpState;

		case 'tbEditor/hideDialog':
			tmpState = {
				...state,
				dialogs: { ...state.dialogs },
			};

			tmpState.dialogs[payload.key] = {
				show: false,
				data: tmpState.dialogs[payload.key]?.data,
			};

			return tmpState;

		case 'tbEditor/updateStats':
			return {
				...state,
				info: {
					...state.info,
					termsCount: payload?.totalTerms ?? 0,
					arLangs: {
						...state.info.arLangs,
						...payload?.langsTerms,
					},
				},
			};

		default:
			return state;
	}
};

export default TBReducer;
