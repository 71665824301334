import ItemsOperation from './itemsOperations';

const defFilters = {
	src: '',
	trg: '',
	fileNames: [],
	sourceTMNames: [],
	workflowSteps: [],
	usersCreated: [],
	usersUpdated: [],
	sortSrc: null,
	sortTrg: null,
	createdFrom: null,
	createdTo: null,
	updatedFrom: null,
	updatedTo: null,
	hasContext: null,
	hasDownvotes: null,
	hasComments: null,
};

const dialogs = {
	searchAndReplace: {
		show: false,
	},
	addLangs: {
		show: false,
	},
	removeLangs: {
		show: false,
	},
};

const initialState = {
	//data
	info: {},
	filterParams: {},
	terms: [],
	termsCount: 0,
	startOfs: 0,
	displayLang: 'en',

	//filters
	filters: { ...defFilters },

	//UI
	loading: false,
	activeItem: null,
	forceRender: 0,

	//actions
	filtersVisible: false, //dialog
	filtersActive: false, //are filters applied to selection
	displayHiddenChars: false,
	displayTags: false,
	expandTags: false,

	dialogs,

	// Search & Replace
	searchAndReplace: {
		find: '',
		replace: '',
		searchIn: 'trg',
	},
};

const TMReducer = (state = initialState, action) => {
	const { payload, type } = action;

	let tmpState;

	switch (type) {
		case 'tmEditor/setInfo':
			return {
				...state,
				...initialState,
				info: { ...payload },
			};

		case 'tmEditor/setFilterParams':
			return {
				...state,
				filterParams: { ...payload },
			};

		case 'tmEditor/setFilter':
			tmpState = {
				...state,
				filters: {
					...state.filters,
					...payload,
				},
			};

			tmpState.filtersActive =
				JSON.stringify(tmpState.filters) != JSON.stringify(defFilters);
			return tmpState;

		case 'tmEditor/clearFilters':
			return {
				...state,
				filters: { ...defFilters },
				filtersActive: false,
			};

		case 'tmEditor/setProperty':
			return {
				...state,
				...payload,
			};

		case 'tmEditor/setActiveItem':
			return ItemsOperation.setActiveItem(state, payload);
		case 'tmEditor/setItemToChecked':
			return ItemsOperation.setItemToChecked(state, payload);
		case 'tmEditor/setItemToCheckedRange':
			return ItemsOperation.setItemToCheckedRange(state, payload);

		case 'tmEditor/updateTerms':
			return ItemsOperation.updateTerms(state, payload);

		case 'tmEditor/setTermsCount':
			//reset terms if not same count
			if (state.termsCount != payload) {
				state = {
					...state,
					termsCount: payload,
					terms: new Array(payload).fill(null),
				};
			}
			return state;

		case 'tmEditor/setTerms':
			const { startOfs, arTerms } = payload;
			tmpState = {
				...state,
				terms: [...state.terms],
			};

			arTerms.map((T, i) => {
				T._source.id = T._id;
				T._source.checked = false;

				const termIdx = parseInt(startOfs) + i;

				const hasRecord = !!tmpState.terms[termIdx];

				if (hasRecord) {
					return;
				}

				T._source.idx = termIdx;

				T._source.hasChanges = false;

				tmpState.terms[termIdx] = T._source;
			});

			return tmpState;
			break;
		case 'tmEditor/updateTerm':
			return ItemsOperation.updateTerm(state, payload);
		case 'tmEditor/removeTerms':
			return ItemsOperation.removeTerms(state, payload);

		case 'tmEditor/reset':
			return null;

		case 'tmEditor/setSearchAndReplace':
			tmpState = {
				...state,
			};

			tmpState.searchAndReplace = {
				...tmpState.searchAndReplace,
				...payload,
			};

			return tmpState;

		case 'tmEditor/showDialog':
			tmpState = {
				...state,
				dialogs: { ...state.dialogs },
			};

			tmpState.dialogs[payload.key] = {
				show: true,
				data: payload?.data
					? payload.data
					: tmpState.dialogs[payload.key]?.data,
			};

			return tmpState;

		case 'tmEditor/hideDialog':
			tmpState = {
				...state,
				dialogs: { ...state.dialogs },
			};

			tmpState.dialogs[payload.key] = {
				show: false,
				data: tmpState.dialogs[payload.key]?.data,
			};

			return tmpState;

		default:
			return state;
	}
};

export default TMReducer;
