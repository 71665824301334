import * as gtag from 'libs/gtag';
import DefaultSEO from 'libs/SEO';
import { SessionProvider as AuthProvider } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { useStore, deserializeState } from 'redux/store';
import { SWRConfig } from 'swr';

const App = ({ Component, pageProps }) => {
	const store = useStore(
		pageProps.initialReduxState != null
			? deserializeState(pageProps.initialReduxState)
			: {}
	);
	const router = useRouter();

	useEffect(() => {
		const handleRouteChange = (url) => {
			gtag.pageview(url);
			try {
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'auto',
				});
			} catch (err) {
				console.log(err);
			}
		};

		router.events.on('routeChangeComplete', handleRouteChange);

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [router.events]);

	return (
		<SWRConfig value={{ fetcher: (url) => fetch(url).then((r) => r.json()) }}>
			<Provider store={store}>
				<DefaultSEO />

				<AuthProvider session={pageProps.session}>
					<Component {...pageProps} />
				</AuthProvider>
			</Provider>
		</SWRConfig>
	);
};

export default App;
