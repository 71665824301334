import { SEGMENT_STATUS } from 'libs/utils';
import _ from 'lodash';
import SegmentsMap from 'libs/segmentsMap';
import ModelSegments from 'models/segments';
import {
	escapeHTML,
	escapeRegExp,
	getMultipleIndexes,
	isNumeric,
} from './commonFuncs';

let searchReplaceLastTerm = '';

class SegmentOperations {
	static initSegments(state, segments) {
		const firstAvailable = segments.find((S) => S.available);

		const arSegments = new SegmentsMap(
			segments.map((S) => [
				S.id,
				{ ...S, checked: false, saving: false, editable: true },
			])
		);

		const arDisplaySegments = state.showAvailableOnly
			? arSegments.filter(({ available }) => available)
			: arSegments;

		const repetitionsCache = {};

		arSegments.forEach((S) => {
			if (S.isInitRepetition || !S.isRepetition) return;

			const arr = !(S.sameAs in repetitionsCache)
				? (repetitionsCache[S.sameAs] = [])
				: repetitionsCache[S.sameAs];

			arr.push(S.id);
		});

		const __state = {
			...state,
			arSegments,
			arDisplaySegments,
			forceRender: new Date(),
			areAllConfirmed: this.areAllConfirmed(arSegments),
			segmentIDToFocus: firstAvailable?.id,
			repetitionsCache,
		};

		return __state;
	}

	static updateTranslationTextRepetitions(
		repetitionsCache,
		arSegments,
		thisSegment
	) {
		return arSegments.withMutations((arSegments) => {
			(repetitionsCache[thisSegment.id] ?? []).forEach((rId) => {
				if (!arSegments.has(rId)) return;

				const repSegment = { ...arSegments.getSegment(rId) };

				//set text and status and save
				repSegment.status = thisSegment.status;
				repSegment.nextStatus = thisSegment.nextStatus;
				repSegment.translationText = thisSegment.translationText;

				if (repSegment.translationText === '') {
					repSegment.matchType = '';
					repSegment.matchScore = '';
				}

				repSegment.forSave = true;

				arSegments.setSegment(rId, repSegment);
			});
		});
	}

	static updateSegmentsChecked(activeSegment, arSegments) {
		return arSegments.map((S) => {
			const isActive = activeSegment?.id == S.id;

			if (isActive && !S.checked) {
				return { ...S, checked: true };
			}

			if (!isActive && S.checked) {
				return { ...S, checked: false };
			}

			return S;
		});
	}

	static setActiveSegment(state, activeSegment) {
		return {
			...state,
			activeSegment: activeSegment,
			actSegment: activeSegment,
			arSegments: this.updateSegmentsChecked(activeSegment, state.arSegments),
			arDisplaySegments: this.updateSegmentsChecked(
				activeSegment,
				state.arDisplaySegments
			),
		};
	}

	static copySourceToTargetProcessSegmentsMap(arSegments) {
		return arSegments.map((S) => {
			if (!S.checked) return S;

			return { ...S, translationText: S.sourceText };
		});
	}

	static copySourceToTarget(state) {
		//for all checked
		return {
			...state,
			arSegments: this.copySourceToTargetProcessSegmentsMap(state.arSegments),
			arDisplaySegments: this.copySourceToTargetProcessSegmentsMap(
				state.arDisplaySegments
			),
			forceRender: new Date(),
		};
	}

	static deleteTargetTextProcessSegmentsMap(arSegments) {
		return arSegments.map((S) => {
			if (!S.checked) return S;

			return { ...S, translationText: '' };
		});
	}

	static deleteTargetText(state) {
		//for all checked
		return {
			...state,
			arSegments: this.deleteTargetTextProcessSegmentsMap(state.arSegments),
			arDisplaySegments: this.deleteTargetTextProcessSegmentsMap(
				state.arDisplaySegments
			),
			forceRender: new Date(),
		};
	}

	static setSegmentToSelectionChecked(state, payload) {
		const { segID, checked } = payload;

		//for all checked
		const newState = {
			...state,
			forceRender: new Date(),
		};

		let { arSegments, arDisplaySegments } = newState;

		if (!arSegments.hasSegment(segID)) return newState;
		const _S = arSegments.getSegment(segID);

		arSegments = arSegments.setSegment(segID, { ..._S, checked });

		newState.activeSegment = null;

		if (arDisplaySegments.hasSegment(segID)) {
			arDisplaySegments = arDisplaySegments.setSegment(segID, {
				..._S,
				checked,
			});
		}

		newState.arSegments = arSegments;
		newState.arDisplaySegments = arDisplaySegments;

		return newState;
	}

	static setSegmentCheckedToRange(state, payload) {
		const { segID } = payload;

		const newState = {
			...state,
			forceRender: new Date(),
		};

		let { arSegments, arDisplaySegments } = newState;

		if (!arSegments.hasSegment(segID)) return state;
		const _S = arSegments.getSegment(segID);

		//find 1st segment checked
		const firstCheckedSegment = arSegments
			.filter(({ checked }) => checked)
			.sortBy(({ ordr }) => ordr)
			.first(null);

		if (!firstCheckedSegment)
			//not found!
			return state;

		let i;

		const minOrder = _S?.ordr ?? 0;
		const maxOrder = firstCheckedSegment?.ordr ?? 0;

		//no change
		if (minOrder == maxOrder) return state;

		const processSegments = (segments) => {
			return segments.map((S) => {
				const _ordr = S.ordr;

				if (
					(minOrder < maxOrder && _ordr >= minOrder && _ordr <= maxOrder) ||
					(minOrder > maxOrder && _ordr >= maxOrder && _ordr <= minOrder)
				) {
					return { ...S, checked: true };
				}

				return S;
			});
		};

		arSegments = processSegments(arSegments);
		arDisplaySegments = processSegments(arDisplaySegments);

		newState.arSegments = arSegments;
		newState.arDisplaySegments = arDisplaySegments;

		//unset focus
		newState.activeSegment = null;

		return newState;
	}

	static selectSegments(state, payload) {
		const newState = {
			...state,
			allSegmentsChecked: payload,
			forceRender: new Date(),
		};

		let { arSegments, arDisplaySegments } = newState;

		const processSegments = (segments) => {
			return segments.map((S) => {
				let checkAction = null;

				if (payload === 'all') {
					checkAction = true;
				}

				if (payload === 'none') {
					checkAction = false;
				}

				if (checkAction == null) {
					if (S.status == payload) {
						checkAction = true;
					} else if (payload === 'locked' && S.isLocked) {
						checkAction = true;
					} else if (payload === 'firstRepetition' && S.isInitRepetition) {
						checkAction = true;
					} else if (
						payload === 'allRepetitions' &&
						(S.isInitRepetition || S.isRepetition)
					) {
						checkAction = true;
					} else {
						checkAction = false;
					}
				}

				if (S.checked !== checkAction) {
					return { ...S, checked: checkAction };
				}

				return S;
			});
		};

		arSegments = processSegments(arSegments);
		arDisplaySegments = processSegments(arDisplaySegments);

		newState.arSegments = arSegments;
		newState.arDisplaySegments = arDisplaySegments;

		return newState;
	}

	static updateRepetitions(newState, newSegment) {
		if (!newSegment.isInitRepetition) return newState;

		const userSettings = window &&
			JSON.parse(window.localStorage.getItem(
				'userPreferences'
			));

		const isAutoPopulateRepetitions = !!userSettings?.autoPopulateRepetitions;

		const processSegments = (segments) => {
			return segments.map((S) => {
				if (S.id == newSegment.id || S.sameAs != newSegment.id) return S;

				if (isAutoPopulateRepetitions && S?.status !== 'confirmed') {
					return S;
				}

				return {
					...S,
					status: newSegment.status,
					nextStatus: newSegment.nextStatus,
					forSave: newSegment.forSave,
					translationText: newSegment.translationText,
				};
			});
		};

		newState.arSegments = processSegments(newState.arSegments);
		newState.arDisplaySegments = processSegments(newState.arDisplaySegments);

		return newState;
	}

	static updateSegment(state, payload) {
		let newState = { ...state };

		const sid = payload?.id;

		if (!newState.arSegments.hasSegment(sid)) return newState;
		const S = newState.arSegments.getSegment(sid);

		const newSegment = {
			...S,
			...payload,
		};

		newState.arSegments = newState.arSegments.setSegment(sid, newSegment);

		if (newState.arDisplaySegments.hasSegment(sid)) {
			newState.arDisplaySegments = newState.arDisplaySegments.setSegment(sid, {
				...newSegment,
			});
		}

		if (newState?.activeSegment && newState?.activeSegment?.id == sid) {
			newState.activeSegment = { ...newSegment };
		}

		//chk for reps
		newState = this.updateRepetitions(newState, newSegment);

		newState.forceRender = new Date();

		newState.areAllConfirmed = this.areAllConfirmed(newState?.arSegments);

		return newState;
	}


	static updateSegments(state, payload) {
		let newState = {
			...state,
		};

		//payload must be array of sements
		payload.forEach((S) => {
			if (!S.id) return;

			if (S?.id === newState?.activeSegment?.id) return;

			const newSeg = {
				...newState.arSegments.getSegment(S.id),
				...S,
				forSave: false,
				updateTM: null,
			};

			newState.arSegments = newState.arSegments.setSegment(S.id, {
				...newSeg,
			});

			if (newState.arDisplaySegments.hasSegment(S.id)) {
				newState.arDisplaySegments = newState.arDisplaySegments.setSegment(
					S.id,
					{
						...newSeg,
					}
				);
			}

			if (newState?.activeSegment && newState?.activeSegment?.id == S.id) {
				newState.activeSegment = { ...newSeg };
			}
		});

		newState.forceRender = new Date();

		newState.areAllConfirmed = this.areAllConfirmed(newState?.arSegments);

		return newState;
	}

	static cleanQaResults(state) {
		let newState = {
			...state,
		};

		newState.arSegments.forEach((S) => {
			if (!S.id) return;

			const newSeg = {
				...newState.arSegments.getSegment(S.id),
				qaResults: [],
				forSave: false,
				updateTM: null,
			};

			newState.arSegments = newState.arSegments.setSegment(S.id, {
				...newSeg,
			});

			if (newState.arDisplaySegments.hasSegment(S.id)) {
				newState.arDisplaySegments = newState.arDisplaySegments.setSegment(
					S.id,
					{
						...newSeg,
					}
				);
			}

			if (newState?.activeSegment && newState?.activeSegment?.id == S.id) {
				newState.activeSegment = { ...newSeg };
			}
		});

		newState.forceRender = new Date();

		newState.areAllConfirmed = this.areAllConfirmed(newState?.arSegments);

		return newState;
	}

	static setLocked() {
		return (dispatch, getState) => {
			const state = getState().traEditor;

			//get selecte segments
			const checkedSegments = state.arSegments
				.filter(({ checked }) => checked)
				.toList()
				.toJS();

			if (checkedSegments.length == 0) return;

			const pairsToLock = checkedSegments.map((s) => ({
				id: s.id,
				isLocked: !s.isLocked,
			}));

			ModelSegments.setLocked({ pairsToLock })
				.then((r) => {
					if (r?.resp) {
						pairsToLock.forEach((P) =>
							dispatch({ type: 'traEditor/updateSegment', payload: P })
						);
						return;
					}
				})
				.catch((err) => {
					console.log(err);
				});
		};
	}

	static setSaving(state, payload) {
		const newState = { ...state };

		const { sid, isSaving } = payload;

		if (!newState.arSegments.hasSegment(sid)) return newState;
		const S = newState.arSegments.getSegment(sid);

		const newSegment = {
			...S,
			saving: isSaving,
			editable: !isSaving,
		};

		newState.arSegments = newState.arSegments.setSegment(sid, newSegment);

		if (newState.arDisplaySegments.hasSegment(sid)) {
			newState.arDisplaySegments = newState.arDisplaySegments.setSegment(sid, {
				...newSegment,
			});
		}

		newState.forceRender = new Date();
		return newState;
	}

	static saveSegment(segment) {
		return (dispatch, getState) => {
			const state = getState().traEditor;

			if (!state.arSegments.hasSegment(segment.id)) return;
			const S = state.arSegments.getSegment(segment.id);

			const { task } = state;

			const pData = {
				segment: {
					...S,
					nextStatus: SEGMENT_STATUS.CONFIRMED,
					targetLang: task.targetLang,
					project: task.project.id,
					file: task.file.id,
					step: task.step,
					task: task.id,
					stepOrdr: task.stepOrdr,
					userName: null,
					userID: null,
				},
			};

			dispatch({
				type: 'traEditor/setSaving',
				payload: { sid: S.id, saving: true },
			});

			ModelSegments.saveSegment(pData)
				.then((r) => {
					if (r) {
						dispatch({ type: 'traEditor/updateSegment', payload: r });
						return;
					}
				})
				.catch((err) => {
					console.log(err);
					//set back segment to editable
				})
				.then((_) => {
					dispatch({
						type: 'traEditor/setSaving',
						payload: { sid: S.id, saving: false },
					});
				});
		};
	}

	static filterAllSegments(state, defSegmentsFilters) {
		const newState = {
			...state,
			segmentsFilters: _.cloneDeep(state.segmentsFilters),
			forceRender: new Date(),
		};

		const initialFilters = _.cloneDeep(defSegmentsFilters);

		const activeFilters = newState.segmentsFilters;

		// Handle unstored (in TMs) segments filter
		let unstoredSegmentsIDs = [];
		if (activeFilters.TMs.unstoredCheckbox) {
			unstoredSegmentsIDs = Object.keys(
				activeFilters?.TMs?.unstoredSegments ?? {}
			);
		}

		const _convertedSource = escapeHTML(activeFilters.text.source);
		const _convertedTarget = escapeHTML(activeFilters.text.target);

		newState.arDisplaySegments = newState.arSegments.filter((segment) => {
			let thisCriteria = true;
			let segmentMatchesCriteria = true;

			const flags = state.caseSensitiveSearch ? 'g' : 'gi';

			// Source text filter
			let regexSrc = state.regexSearch
				? String.raw`${_convertedSource}`
				: escapeRegExp(_convertedSource);
			if (state.wholeWordSearch && _convertedSource)
				regexSrc = String.raw`\b${regexSrc}\b`;
			let regexSource;
			try {
				regexSource = new RegExp(regexSrc, flags);
			} catch (e) {
				return false;
			}
			const sourceWithoutTags = segment.sourceText.replace(/<[^>]+>/gm, '');

			// Target text filter
			let regexTrg = state.regexSearch
				? String.raw`${_convertedTarget}`
				: escapeRegExp(_convertedTarget);
			if (state.wholeWordSearch && _convertedTarget)
				regexTrg = String.raw`\b${regexTrg}\b`;
			let regexTarget;
			try {
				regexTarget = new RegExp(regexTrg, flags);
			} catch (e) {
				return false;
			}
			const targetWithoutTags = segment.translationText.replace(
				/<[^>]+>/gm,
				''
			);

			// Remove non-breaking spaces. &nbsp; is not shown in JS, so the JS equivalent "\xa0" is used.
			const targetWithoutBreakingSpace = targetWithoutTags.replaceAll(
				'\xa0',
				' '
			);

			thisCriteria = regexSource.test(sourceWithoutTags);
			segmentMatchesCriteria =
				segmentMatchesCriteria && thisCriteria && segment?.available;

			thisCriteria = regexTarget.test(targetWithoutBreakingSpace);
			segmentMatchesCriteria =
				segmentMatchesCriteria && thisCriteria && segment?.available;

			if (activeFilters.TMs.unstoredCheckbox) {
				thisCriteria = unstoredSegmentsIDs.includes(segment?.id);
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.status.confirmed) {
				thisCriteria = segment.status === SEGMENT_STATUS.CONFIRMED;
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.status.notConfirmed) {
				thisCriteria = segment.status === SEGMENT_STATUS.DRAFT;
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.status.locked) {
				thisCriteria = segment.isLocked === true;
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.status.notLocked) {
				thisCriteria = segment.isLocked === false;
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.status.empty) {
				thisCriteria = segment.translationText === '';
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.status.notEmpty) {
				thisCriteria = segment.translationText !== '';
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.status.edited) {
				thisCriteria = activeFilters.editedSegments.includes(segment.id);
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.status.notEdited) {
				thisCriteria = !activeFilters.editedSegments.includes(segment.id);
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.lastInput.manualInput) {
				thisCriteria =
					(segment?.matchType === '' || !segment.matchType) &&
					segment.translationText !== '';
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.lastInput.MT) {
				thisCriteria = segment.matchType === 'MT';
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.lastInput.fuzzy) {
				const matchScoreInt = parseInt(segment.matchScore);
				thisCriteria =
					segment.matchType === 'TM' &&
					activeFilters.lastInput.fuzzyValues[0] <= matchScoreInt &&
					matchScoreInt <= activeFilters.lastInput.fuzzyValues[1];

				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.sourceText.showRepeatedSegments) {
				thisCriteria = segment.isInitRepetition;
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.sourceText.showSecondRepetitions) {
				thisCriteria = segment.sameAs > 0;
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			// TODO
			if (activeFilters.sourceText.showRepException) {
				thisCriteria = segment.sameAs < 0;
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.sourceText.targetSameAsSource) {
				thisCriteria =
					segment.translationText === segment.sourceText &&
					segment.translationText !== '' &&
					segment.sourceText !== '';
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.sourceText.showNumbersOnly) {
				thisCriteria = isNumeric(segment.sourceText);
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.sourceText.hideNumbersOnly) {
				thisCriteria = !isNumeric(segment.sourceText);
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.tags.withTags) {
				thisCriteria = segment.sourceText.indexOf('xlftag') !== -1;
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.tags.withoutTags) {
				thisCriteria = segment.sourceText.indexOf('xlftag') === -1;
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.comments.withComments) {
				thisCriteria = segment.commentsCount > 0;
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.comments.withoutComments) {
				thisCriteria = segment.commentsCount < 1;
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.comments.commentsFilters.length > 0) {
				thisCriteria = activeFilters.comments.commentsFilters.some((ct) => {
					const exists = segment?.commentTypes?.find((taskCT) => ct === taskCT);

					return exists;
				});

				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.comments.resolved) {
				thisCriteria = segment?.resolvedCommentsCount > 0;
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.comments.notResolved) {
				thisCriteria =
					segment.commentsCount > 0 &&
					segment?.resolvedCommentsCount < segment.commentsCount;
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.errors.withErrors) {
				thisCriteria = segment?.qaResults?.length > 0;
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			if (activeFilters.errors.withoutErrors) {
				thisCriteria = !segment.qaResults;
				segmentMatchesCriteria =
					segmentMatchesCriteria && thisCriteria && segment?.available;
			}

			return segmentMatchesCriteria;
		});

		newState.filtersActive =
			JSON.stringify(newState.segmentsFilters) !==
			JSON.stringify(initialFilters);

		return newState;
	}

	static searchAndReplaceDoAction(state, payload) {
		const {
			type,
			search,
			replace,
			searchIn,
			actionToDo,
			useRegex,
			caseSensitive,
			wholeWords,
		} = payload;

		let textToUseAsSearch = useRegex ? search : escapeRegExp(search);
		const regexFlags = 'gm' + (caseSensitive ? '' : 'i');

		if (wholeWords) {
			textToUseAsSearch = `\\b${textToUseAsSearch}\\b`;
		}

		const regexToSearch = new RegExp(
			textToUseAsSearch + '(?![^<]*>|[^<>]*</)',
			regexFlags
		);

		const newState = {
			...state,
			dialogs: {
				...state.dialogs,
			},
		};

		let instancesFound = 0;

		const countMatches = (str) => {
			return ((str || '').match(regexToSearch) || []).length;
		};

		newState.arDisplaySegments = newState.arSegments
			.filter((S) => {
				const _seg = S;

				let foundInLine = false;

				if (searchIn == 'both')
					foundInLine =
						regexToSearch.test(_seg.sourceText) ||
						regexToSearch.test(_seg.translationText);

				if (searchIn == 'src')
					foundInLine = regexToSearch.test(_seg.sourceText);

				if (searchIn == 'trg')
					foundInLine = regexToSearch.test(_seg.translationText);

				return foundInLine;
			})
			.map((S) => {
				const _seg = {
					...S,
					isTempLocked: type == 'replace', //used only for search replace
				};

				if (searchIn == 'both') {
					_seg.searchReplaceHighlight = {
						src: search,
						trg: search,
					};
					instancesFound += countMatches(_seg.sourceText);
					instancesFound += countMatches(_seg.translationText);
				}

				if (searchIn == 'src') {
					_seg.searchReplaceHighlight = {
						src: search,
					};
					instancesFound += countMatches(_seg.sourceText);
				}

				if (searchIn == 'trg') {
					_seg.searchReplaceHighlight = {
						trg: search,
					};
					instancesFound += countMatches(_seg.translationText);
				}

				_seg.searchReplaceHighlight = {
					..._seg.searchReplaceHighlight,
					useRegex,
					wholeWords,
					caseSensitive,
				};

				return _seg;
			});

		newState.dialogs.searchAndReplace.data = {
			...newState.dialogs.searchAndReplace.data,
			segmentsFound: newState.arDisplaySegments.size,
			instancesFound: instancesFound,
		};

		const replaceInSegment = (segment) => {
			let oldText = segment.translationText.replace(/<\/?highlight.*?>/g, '');
			segment.translationText = segment.translationText
				.replace(/<\/?highlight.*?>/g, '')
				.replace(regexToSearch, replace);

			if (oldText !== segment.translationText) {
				segment.status = SEGMENT_STATUS.DRAFT;
				segment.nextStatus = SEGMENT_STATUS.DRAFT;
				segment.forSave = true;
			}

			return segment;
		};

		const createPlaceholder = (segment) => {
			// If not using regex, just replace with the text input from user
			let placeholder = search;

			// If using regex don't replace with the regex input, but with the actual match
			if (useRegex) {
				const matches = segment.sourceText.match(regexToSearch);
				placeholder = matches[0] ?? '';
			}

			segment.sourceText = segment.sourceText
				.replace(/<\/?highlight.*?>/g, '')
				.replace(
					regexToSearch,
					`<img src="" class="xlftag" data-type="s" data-tag="p" data-original="${placeholder}" data-idx="${placeholder}" />`
				);
			segment.status = SEGMENT_STATUS.DRAFT;
			segment.nextStatus = SEGMENT_STATUS.DRAFT;
			segment.forSave = true;

			return segment;
		};

		//only in target
		if (type == 'replace') {
			let segToReplace;

			newState.searchReplaceStack = {};

			newState.arDisplaySegments = newState.arDisplaySegments.map((S) => {
				const newS = { ...S };

				newS.translationText = newS.translationText.replace(
					/<\/?highlight.*?>/g,
					''
				);
				newState.searchReplaceStack[S.id] = { ...newS };

				return newS;
			});

			if (actionToDo == 'replace') {
				const replSegment = newState.arDisplaySegments
					.sortBy(({ ordr }) => ordr)
					.first(null);

				if (replSegment) {
					segToReplace = { ...newState.arSegments.getSegment(replSegment.id) };
					newState.arSegments = newState.arSegments.setSegment(
						replSegment.id,
						replaceInSegment(segToReplace)
					);
					newState.arDisplaySegments = newState.arDisplaySegments.setSegment(
						replSegment.id,
						replaceInSegment(segToReplace)
					);
				}
			}

			let replacedSegments = new Map();

			if (actionToDo == 'replaceAll') {
				newState.arSegments.forEach((S) => {
					debugger;
					segToReplace = { ...newState.arSegments.getSegment(S.id) };
					let oldTranslationText = segToReplace?.translationText;
					newState.arSegments = newState.arSegments.setSegment(
						S.id,
						replaceInSegment(segToReplace)
					);
					newState.arDisplaySegments = newState.arDisplaySegments.setSegment(
						S.id,
						replaceInSegment(segToReplace)
					);

					let replacedSegment = { ...newState.arSegments.getSegment(S.id) };

					let repId = parseInt(replacedSegment?.id ?? 0);
					let repTranslationText = replacedSegment?.translationText;

					if (oldTranslationText !== repTranslationText) {
						replacedSegments.set(repId, {
							id: replacedSegment.id,
							ordr: replacedSegment?.ordr,
							oldTranslationText: oldTranslationText,
							newTranslationText: repTranslationText,
							segment: replacedSegment
						})
					}
				});
			}

			if (actionToDo == 'createPlaceholder') {
				newState.arDisplaySegments.forEach((S) => {
					segToReplace = { ...newState.arSegments.getSegment(S.id) };
					newState.arSegments = newState.arSegments.setSegment(
						S.id,
						createPlaceholder(segToReplace)
					);
				});
			}

			newState.replacedSegments = replacedSegments;
		}

		return newState;
	}

	static undoSearchAndReplace(state) {
		const newState = {
			...state,
			lastUpdate: new Date(),
		};

		for (let id in newState.searchReplaceStack) {
			const s = newState.searchReplaceStack[id];
			s.forSave = true;
			s.translationText = s.translationText.replace(/<\/?highlight.*?>/g, '');

			newState.arSegments = newState.arSegments.setSegment(id, { ...s });

			if (newState.arDisplaySegments.hasSegment(id)) {
				newState.arDisplaySegments = newState.arDisplaySegments.setSegment(id, {
					...s,
				});
			}
		}

		newState.searchReplaceStack = null;
		newState.replacedSegments = [];

		return newState;
	}

	static searchAndReplaceClear(state) {
		const newState = {
			...state,
			arDisplaySegments: state.arSegments,
			searchReplaceStack: null,
			lastUpdate: new Date(),
		};

		newState.arDisplaySegments = newState.arDisplaySegments.map((S) => {
			if ('searchReplaceHighlight' in S) {
				const newS = { ...S };
				delete newS.searchReplaceHighlight;
				return newS;
			}

			return S;
		});

		return newState;
	}

	static updateQAIngoreState(state, payload) {
		const _newState = {
			...state,
			lastUpdate: +new Date(),
		};

		const { segmentID, type, newState } = payload;
		const seg = {
			..._newState.arSegments.getSegment(segmentID),
		};
		seg.lastUpdate = +new Date();

		seg.qaResults = seg.qaResults.map((E) => {
			if (E.qaTest == type) E.isIgnored = newState;

			return E;
		});

		_newState.arSegments = _newState.arSegments.setSegment(segmentID, seg);

		if (_newState.arDisplaySegments.hasSegment(segmentID)) {
			_newState.arDisplaySegments = _newState.arDisplaySegments.setSegment(
				segmentID,
				{
					...seg,
				}
			);
		}

		return _newState;
	}

	static areAllConfirmed(segments) {
		return segments.every((S) => {
			return (
				S.isLocked || !S.available || S.status === SEGMENT_STATUS.CONFIRMED
			);
		});
	}

	static updateSegmentResolvedComments(state, payload) {
		let newState = { ...state };

		const sid = payload?.id;
		if (!newState.arSegments.hasSegment(sid)) return newState;
		const S = newState.arSegments.getSegment(sid);

		const newSegment = {
			...S,
			...payload,
		};

		newSegment.resolvedCommentsCount = payload.increaseResolvedCount
			? newSegment.resolvedCommentsCount + 1
			: newSegment.resolvedCommentsCount - 1;

		newState.arSegments = newState.arSegments.setSegment(sid, newSegment);

		if (newState.arDisplaySegments.hasSegment(sid)) {
			newState.arDisplaySegments = newState.arDisplaySegments.setSegment(sid, {
				...newSegment,
			});
		}

		if (newState?.activeSegment && newState?.activeSegment?.id == sid) {
			newState.activeSegment = { ...newSegment };
		}

		newState.forceRender = new Date();

		return newState;
	}
}

export default SegmentOperations;
