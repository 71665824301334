import _ from 'lodash';

const initialState = {
    branch: 'master',
    buildDate: ''
};

const SettingsReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case 'settings/setProp':
            return { ...state, ...payload };
        case 'settings/setBranch':
            return { ...state, branch: payload };

        case 'settings/setBuildDate':
            return { ...state, buildDate: payload };
        default:
            return state;
    }
};

export default SettingsReducer;
