
const initialState = {
	langs: {},
	loading: false,
	languages: {}
}

let newState;

const LangsReducer = (state = initialState, action) => {

	const { payload, type } = action;

	switch (type) {
		case 'langs/setProp':
			return {
				...state,
				...payload
			}
		case "langs/setLangs": //keep minimal info, id as key
			newState = {
				...state,
				langs: {}
			};

			payload.forEach( L => {
				newState.langs[L.id] = { name: L.name, isActive: L.isActive == "1" };
			});
			return newState;
				
			break;

		case "langs/setLanguages":
			newState = {
				...state,
				languages: payload
			};
			return newState;
		default:
			return state
	}
}

export default LangsReducer;