import produce from 'immer';
import { escapeRegExp } from '../../translationEditor/commonFuncs';

class ItemsOperation {
	static setActiveItem(state, payload) {
		//get active item from stack in order to reflect any changes not saved yet
		return produce(state, (newState) => {
			newState.activeItem =
				newState.terms.find((T) => T?.id == payload?.id) ?? null;
			newState.terms = newState.terms.map((T) => {
				if (!T) return null;

				T.checked = T.id == payload?.id;
				return T;
			});
		});
	}

	static setItemToChecked(state, payload) {
		const { id, checked } = payload;
		const newState = {
			...state,
			activeItem: null,
			terms: state.terms.map((T) => {
				if (!T) return null;

				if (id == T.id) {
					T.checked = checked;
				}

				return T;
			}),
		};

		return newState;
	}

	static setItemToCheckedRange(state, payload) {
		const { index } = payload;

		const newState = {
			...state,
			terms: [...state.terms],
			forceRender: new Date(),
		};

		//find 1st segment checked
		const firstCheckedSegment = newState.terms.find((s) => s.checked);
		if (!firstCheckedSegment)
			//not found!
			return newState;

		let i;

		if (firstCheckedSegment.idx < index) {
			for (i = firstCheckedSegment.idx; i <= index; i++) {
				newState.terms[i].checked = true;
			}
			newState.activeSegment = null;
		}

		if (firstCheckedSegment.idx > index) {
			for (i = firstCheckedSegment.idx; i >= index; i--) {
				newState.terms[i].checked = true;
			}
			newState.activeSegment = null;
		}

		return newState;
	}

	static updateTerm(state, payload) {
		return produce(state, (newState) => {
			newState.forceRender = new Date();
			newState.terms = newState.terms.map((T) => {
				if (!T) return null;

				if (T.id == payload.id) {
					T = produce(T, (newT) => {
						//update any given pair
						Object.keys(payload).forEach((k) => {
							newT[k] = payload[k];
						});

						newT.hasChanges = true;
						newT.metas = {
							...newT.metas,
							...payload.metas,
						};
					});
				}

				return T;
			});

			if (payload.id == newState?.activeItem?.id) {
				newState.activeItem = {
					...newState.activeItem,
					...payload,
				};
			}
		});
	}

	static removeTerms(state, payload) {
		const { ids, langsTerms } = payload;

		if (!ids || ids.length == 0) return state;

		return produce(state, (newState) => {
			newState.terms = state.terms.filter((T) => {
				if (T && ids.includes(T.id)) return false;

				return true;
			});

			newState.forceRender = new Date();

			if (newState.activeSegment && ids.includes(newState.activeSegment.id))
				newState.activeSegment = null;

			newState.info.arLangs = {
				...newState.info.arLangs,
				...langsTerms,
			};

			//stats
			newState.info.termsCount -= ids.length;
			newState.termsCount -= ids.length;

			if (newState.info.termsCount < 0) newState.info.termsCount = 0;

			if (newState.termsCount < 0) newState.termsCount = 0;
		});
	}

	static updateTerms(state, payload) {
		const { items } = payload;

		if (!items || items.length == 0) return state;

		const ids = items.map((I) => I.id);

		return produce(state, (newState) => {
			newState.forceRender = new Date();
			newState.terms = newState.terms.map((T) => {
				if (!T) return null;

				if (ids.includes(T.id)) {
					//update this
					//const updatedItem = items.find( I => I.id == T.id);

					T.hasChanges = false;
				}

				return T;
			});
		});
	}

	static searchAndReplaceDoAction(state, payload) {
		const {
			type,
			search,
			replace,
			searchIn,
			actionToDo,
			useRegex,
			caseSensitive,
		} = payload;

		const textToUseAsSearch = useRegex ? search : escapeRegExp(search);
		const regexFlags = 'gm' + (caseSensitive === true ? '' : 'i');
		const regexToSearch = new RegExp(
			textToUseAsSearch + '(?![^<]*>|[^<>]*</)',
			regexFlags
		);

		const newState = {
			...state,
			arDisplaySegments: { ...state.arDisplaySegments },
			arSegments: { ...state.arSegments },
			dialogs: {
				...state.dialogs,
			},
		};

		let _foundSegments = {};
		let instancesFound = 0;

		const countMatches = (str) => {
			return ((str || '').match(regexToSearch) || []).length;
		};

		for (let id in newState.arSegments) {
			const _seg = {
				...newState.arSegments[id],
				isTempLocked: type == 'replace', //used only for search replace
			};

			let foundInLine = false;

			if (searchIn == 'both')
				foundInLine =
					regexToSearch.test(_seg.sourceText) ||
					regexToSearch.test(_seg.translationText);

			if (searchIn == 'src') foundInLine = regexToSearch.test(_seg.sourceText);

			if (searchIn == 'trg')
				foundInLine = regexToSearch.test(_seg.translationText);

			//console.log(foundInLine);

			if (foundInLine) {
				if (searchIn == 'both') {
					_seg.searchReplaceHighlight = {
						src: search,
						trg: search,
					};
					instancesFound += countMatches(_seg.sourceText);
					instancesFound += countMatches(_seg.translationText);
				}

				if (searchIn == 'src') {
					_seg.searchReplaceHighlight = {
						src: search,
					};
					instancesFound += countMatches(_seg.sourceText);
				}

				if (searchIn == 'trg') {
					_seg.searchReplaceHighlight = {
						trg: search,
					};
					instancesFound += countMatches(_seg.translationText);
				}

				//append segment to stack
				_foundSegments[id] = _seg;
			}
		}

		newState.arDisplaySegments = { ..._foundSegments };
		newState.dialogs.searchAndReplace.data = {
			...newState.dialogs.searchAndReplace.data,
			segmentsFound: Object.keys(_foundSegments).length,
			instancesFound: instancesFound,
		};

		const replaceInSegment = (segment) => {
			segment.translationText = segment.translationText
				.replace(/<\/?highlight.*?>/g, '')
				.replace(regexToSearch, replace);
			segment.status = 'draft';
			segment.nextStatus = 'draft';
			segment.forSave = true;

			return segment;
		};

		//only in target
		if (type == 'replace') {
			let segToReplace;

			newState.searchReplaceStack = {};

			Object.keys(newState.arDisplaySegments).map((sid) => {
				//remove highlight
				const S = newState.arDisplaySegments[sid];
				S.translationText = S.translationText.replace(/<\/?highlight.*?>/g, '');
				newState.searchReplaceStack[sid] = { ...S };
			});

			if (actionToDo == 'replace') {
				const replSegmentID =
					Object.keys(newState.arDisplaySegments)?.[0] ?? null;
				if (replSegmentID) {
					segToReplace = { ...newState.arSegments[replSegmentID] };
					newState.arSegments[replSegmentID] = replaceInSegment(segToReplace);
				}
			}

			if (actionToDo == 'replaceAll') {
				Object.keys(newState.arDisplaySegments).forEach((sid) => {
					segToReplace = { ...newState.arSegments[sid] };
					newState.arSegments[sid] = replaceInSegment(segToReplace);
				});
			}
		}

		return newState;
	}

	static undoSearchAndReplace(state) {
		const newState = {
			...state,
			arDisplaySegments: { ...state.arDisplaySegments },
			arSegments: { ...state.arSegments },
			lastUpdate: new Date(),
		};

		for (let id in newState.searchReplaceStack) {
			const s = newState.searchReplaceStack[id];
			s.forSave = true;
			s.status = 'draft';
			s.nextStatus = 'draft';
			s.translationText = s.translationText.replace(/<\/?highlight.*?>/g, '');

			newState.arSegments[id] = { ...s };
			if (typeof newState.arDisplaySegments[id] != 'undefined') {
				newState.arDisplaySegments[id] = { ...s };
			}
		}

		newState.searchReplaceStack = null;

		return newState;
	}

	static searchAndReplaceClear(state) {
		const newState = {
			...state,
			arDisplaySegments: { ...state.arSegments },
			searchReplaceStack: null,
			lastUpdate: new Date(),
		};

		for (let sid in newState.arDisplaySegments) {
			delete newState.arDisplaySegments[sid].searchReplaceHighlight;
		}

		return newState;
	}
}

export default ItemsOperation;
