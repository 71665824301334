import { Map as SegmentsMap } from 'immutable';

SegmentsMap.prototype.getSegment = function (id) {
	if (typeof id === 'string') return this.get(id);

	return this.get(id.toString());
};

SegmentsMap.prototype.hasSegment = function (id) {
	if (typeof id === 'string') return this.has(id);

	return this.has(id.toString());
};

SegmentsMap.prototype.setSegment = function (id, S) {
	if (typeof id === 'string') return this.set(id, S);

	return this.set(id.toString(), S);
};

export default SegmentsMap;
